<template>
  <section class="container">
    <bread-crumbs
      class="breadcrumbs--tablet-gap-small breadcrumbs--on-item-mobile"
      :breadcrumbs-list="crumbs"
    ></bread-crumbs>
    <div class="cabinet">
      <h1>{{ rootStore.generalMeta?.h1 || title }}</h1>
      <div class="cabinet_wrapper">
        <div class="cabinet__aside">
          <aside-collapsible :list="cabinetStore.asideMenu"></aside-collapsible>
          <base-button text="выйти" full color="grey" @click="logout()"></base-button>
        </div>
        <div class="cabinet__body">
          <slot></slot>
        </div>
      </div>
    </div>
  </section>
</template>

<script setup lang="ts">
import 'sass/cabinet/index.sass';
import 'sass/aside/collapsible.sass';

import {type PropType, computed, useSeoMeta} from '#imports';
import BaseButton from '@/components/Base/Button.vue';
import AsideCollapsible from '@/components/aside/collapsible/index.vue';
import BreadCrumbs from '@/components/breadcrumbs/index.vue';
import {useApiMeta} from '@/composables/useMeta';
import {useAuthStore} from '@/stores/auth';
import {useCabinetStore} from '@/stores/cabinet';
import {useRootStore} from '@/stores/root';
import type ICrumbs from '@/types/store/products';

defineProps({
  crumbs: {type: Array as PropType<ICrumbs[]>, required: true},
});

const rootStore = useRootStore();
const cabinetStore = useCabinetStore();
const authStore = useAuthStore();

const DEFAULT_TITLE = 'Добро пожаловать в личный кабинет';

const title = computed(() => rootStore.generalMeta?.h1 || DEFAULT_TITLE);

useSeoMeta({
  title: DEFAULT_TITLE,
});

const logout = async () => {
  await authStore.logout();
};

useApiMeta();
</script>
